import React from 'react'
import { Link } from 'gatsby'

const Footer = (props) => {
  return (
    <footer className='footer has-text-centered'>
      <div className='columns is-flex px-6'>
        <div className='column'>
          <span>
            9500 Gilman Dr.
            <p>
              La Jolla, CA 92093
            </p>
          </span>
        </div>
        <div className='column'>
          <span>
            Products
          </span>
        </div>
        <div className='column'>
          <span>
            Our Supporters
          </span>
        </div>
        <div className='column'>
          <span>
            Terms of Services
          </span>
        </div>
      </div>

      <div className='columns is-flex mb-1 px-6'>
        <div className='column'>
          <span>
            (949) 439-6690
          </span>
        </div>
        <div className='column'>
          <span>
            How it Works
          </span>
        </div>
        <div className='column'>
          <span>
            Careers
          </span>
        </div>
        <div className='column'>
          <span>
            Privacy Policy
          </span>
        </div>
      </div>

      <div className='columns is-flex px-6'>
        <div className='column mb-2'>
          <a href='mailto:sales@nimberinc.com' className='footer-link'>
            sales@nimberinc.com
          </a>
        </div>
        <div className='column'>
          <span />
        </div>
        <div className='column'>
          <span />
        </div>
        <div className='column'>
          <Link to='/contact' class='footer-link'>
            Contact
          </Link>
        </div>
      </div>
    </footer>
  )
}

export default Footer
