module.exports = {
  siteTitle: 'Nimber Businesses website', // Site title.
  siteTitleAlt: 'NimberBusiness', // Alternative site title for SEO.
  siteLogo: '/icons/Logo.png', // Logo used for SEO and manifest.
  siteUrl: 'https://gatsby-starter-business.netlify.com', // Domain of your website without pathPrefix.
  pathPrefix: '', // Prefixes all links. For cases when deployed to example.github.io/gatsby-starter-business/.
  siteDescription: 'Business website', // Website description used for RSS feeds/meta description tag.
  siteRss: '/rss.xml',
  siteFBAppID: '', // FB Application ID for using app insights
  googleTagManagerID: '', // GTM tracking ID.
  disqusShortname: 'nimberinc', // Disqus shortname.
  userName: 'Nimber',
  userTwitter: 'Nimber',
  userLocation: 'Seattle WA',
  userDescription: '',
  copyright: 'Copyright © Nimber Inc 2020. All Rights Reserved.', // Copyright string for the footer of the website and RSS feed.
  themeColor: '#00d1b2', // Used for setting manifest and progress theme colors.
  backgroundColor: '#ffffff', // Used for setting manifest background color.
  cookieConsent: 'This website uses cookies which are used to collect anonymous information to improve your browsing experience and for analytics and metrics.', // @TODO Add GDPR Cookie Consent
}
